import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'
import MUISelect, { SelectChangeEvent, SelectProps } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import CardMedia from '@mui/material/CardMedia'
import { FieldProps } from 'formik'
import Icon from '../Icon'
import { Checkbox } from '../Checkbox'
import InfoTooltip from '../InfoTooltip'
import Flag from 'features/Flag'
import countryService from 'services/country.service'
import { AccountData } from 'components/WalletViews'
import { CardAccountData } from 'components/CardViews'

import useStyles from './styles'

export type TOption = {
  value: string
  label: string | JSX.Element
  flag?: string
  image?: string
  avatar?: string
  icon?: string
  iconSize?: number
  info?: string
  currency?: string
  isCard?: boolean
  isWallet?: boolean
  amount?: string | number
  pan?: string
  id?: string | number
  caption?: string
  account?: string
  rightIcon?: string
  rightIconSize?: number
  rightIconColor?: string
  rightText?: string
  rightTextColor?: string
}

interface ISelect extends SelectProps {
  className?: string
  options: TOption[]
  placeholder?: string
  withFlag?: boolean
  showSelect?: boolean
  search?: boolean
  errorMessage?: string
  handleChange?: (e: ChangeEvent<{}>, data: TOption | null) => void
  getOptionSelected?: any
  itemRightHandler?: (data: TOption) => void
  t?: Function
  disableClearable?: boolean
  clearOnEscape?: boolean
  autoHighlight?: boolean
  isOptionEqualToValue?: Function
}

interface IFormikSelectProps extends ISelect, FieldProps {
}

const PoorSelect = ({
                      name,
                      open,
                      options,
                      className = '',
                      withFlag = false,
                      inputProps = {},
                      placeholder = '',
                      value,
                      showSelect = false,
                      t,
                      ...props
                    }: ISelect) => {
  const { classes } = useStyles()

  const onRightPress = useCallback((item: TOption) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    props?.itemRightHandler && props.itemRightHandler(item);
  }, [props.itemRightHandler])

  return (
    <MUISelect
      variant="outlined"
      className={classnames(classes.root, className, { [classes.height56]: props.size === 'medium' })}
      MenuProps={{
        classes: { paper: classes.paper },
      }}
      IconComponent={() =>
        (!options.length || (options.length === 1 && !!value && value !== 'auth-user')) ? null : (
          <IconButton
            className={classes.chevronBtn}
            onClick={open || props.disabled ? props.onClose : props.onOpen}
          >
            <Icon icon="chevron-right" size={18}/>
          </IconButton>
        )
      }
      inputProps={{
        name,
        id: name,
        ...inputProps,
      }}
      value={value || placeholder}
      open={open}
      {...props}
    >
      {!!placeholder && !value && !open && (
        <MenuItem disabled value={placeholder} key="placeholder0" className={classes.menuItemCommon}>
          <Typography variant={"subtitle2"} sx={{color: 'designGrey.300'}}>
            {placeholder}
          </Typography>
        </MenuItem>
      )}
      {options.map((option, idx) => (
        <MenuItem
          key={option.value.toString() + idx}
          value={option.value}
          data-currency={option.currency}
          data-amount={option.amount}
          data-pan={option.pan}
          data-caption={option.caption}
          data-account={option.account}
          className={props.multiple ? classes.menuItemMultiple : classes.menuItemCommon}
        >
          {props.multiple &&
            <Checkbox checked={(value as string[]).includes(option.value)} className={classes.multiCheckbox}/>}
          {withFlag && option.flag && (
            <Box className={classnames({ [classes.flagWrapper]: open, [classes.flag]: true })}>
              <Flag country={option.flag as string} size={16}/>
            </Box>
          )}
          {option.avatar && <Avatar src={option.avatar} alt="user" className={classes.avatar}/>}
          {option.image && <CardMedia component="img" src={option.image} className={classes.image}/>}
          {option.icon && (
            <Box minWidth={22} height={24}>
              <Icon icon={option.icon} size={option.iconSize || 22} className={classes.icon}/>
            </Box>
          )}
          {!option.isCard && !option.isWallet && typeof option.label === 'string' && (
            <Typography variant="subtitle2" color="inherit" className={classes.menuLabel}>
              {option.label}
            </Typography>
          )}
          {!option.isCard && !option.isWallet && !!option.label && typeof option.label !== 'string' && (
            option.label
          )}
          {(!!option.info && open && option.value !== value) && (
            <Box className={classes.infoTooltip}>
              <Box width={10}/>
              <InfoTooltip title={option.info} placement="top"/>
            </Box>
          )}
          {(showSelect && option.value === value && open) && (
            <Box className={classnames(classes.rightPart, classes.selectedItem)}>
              <Icon icon="check-bold" size={6.5} color={"#fff"}/>
            </Box>
          )}
          {!!option.rightIcon && (
            <Box
              className={classes.rightPart}
              onClick={!onRightPress ? () => {
              } : onRightPress(option)}
            >
              <Icon
                icon={option.rightIcon}
                size={option.rightIconSize || 20}
                color={option.rightIconColor || '#45494D'}
              />
            </Box>
          )}
          {!!option.rightText && (
            <Box
              className={classes.rightPart}
              onClick={!onRightPress ? () => {
              } : onRightPress(option)}
              fontWeight={600}
              color={option.rightTextColor || 'grey.500'}
            >
              <Typography variant={"body1"} color={"inherit"}>
                {option.rightText}
              </Typography>
            </Box>
          )}
          {option.isCard &&
            <CardAccountData
              caption={option.label as string}
              amount={option.amount || 0}
              currency={option.currency as string}
              pan={option.pan || ''}
              selected={open && option.value === value}
              className={open ? 'onHoverMenu' : ''}
              sx={{ width: '100%', padding: 0.5 }}
            />
          }
          {option.isWallet &&
            <AccountData
              amount={option.amount || 0}
              currency={option.currency as string}
              selected={open && option.value === value}
              className={"onHoverMenu"}
              sx={{ width: '100%', padding: 0.5 }}
              t={t as Function}
            />
          }
        </MenuItem>
      ))}
    </MUISelect>
  )
}

const CustomSelect = ({
                        name,
                        options,
                        className = '',
                        withFlag = false,
                        search = false,
                        label,
                        inputProps = {},
                        errorMessage = '',
                        ...props
                      }: ISelect) => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation('validation')

  const [open, setOpen] = useState<boolean>(false)

  const renderRecipientAvatar = useCallback((data: string) => {
    const words = data.split(/\s+/)
    if (words.length === 2) {
      return words[0][0].toUpperCase() + words[1][0].toUpperCase()
    } else if (words.length === 3) {
      return words[0][0].toUpperCase() + words[2][0].toUpperCase()
    }
  }, [])

  return (
    <FormControl fullWidth error={!!errorMessage} className={classnames(classes.formikRoot, className)}>
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      {search ? (
        <Autocomplete
          autoSelect
          clearOnEscape={props.hasOwnProperty('clearOnEscape') ? props.clearOnEscape : true}
          disableClearable={props.hasOwnProperty('disableClearable') ? props.disableClearable : true}
          autoHighlight={props.hasOwnProperty('autoHighlight') ? props.autoHighlight : true}
          open={(options.length === 1 && !!props.value) || !options.length ? false : open}
          disabled={props.disabled || (options.length === 1 && !!props.value) || !options.length}
          popupIcon={props.disabled || (options.length === 1 && !!props.value) || !options.length
            ? null
            : <Icon icon="chevron-right" size={18}/>
          }
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onFocus={props.onFocus as any}
          classes={{
            root: classes.autoCompleteRoot,
            paper: classes.paper,
            option: classnames({
              [classes.optionMultiple]: props.multiple,
              [classes.option]: !props.multiple,
              [classes.addRecipient]: name === 'recipient',
            })
          }}
          renderInput={(params) => {
            const { InputProps, inputProps: innerProps, ...restParams } = params
            const selectedOption = props.value ? options.find((option) => option.value === props.value) : null
            if (!open && selectedOption && (innerProps as any).value !== selectedOption.label) {
              (innerProps as any).value = selectedOption.label
            }
            if (!open && !selectedOption && (innerProps as any).value) {
              (innerProps as any).value = ''
            }
            return (
              <TextField
                variant="outlined"
                {...restParams}
                error={!!errorMessage}
                inputProps={{ ...innerProps, ...inputProps, name, id: name }}
                InputProps={{
                  ...InputProps,
                  className: '',
                  startAdornment:
                    !open && selectedOption && selectedOption.flag ? (
                      <InputAdornment position="start">
                        <Flag country={selectedOption.flag as string} size={18}/>
                      </InputAdornment>
                    ) : null,
                }}
              />
            )
          }}
          onChange={props.handleChange}
          options={options}
          filterOptions={(allOptions, { inputValue }) => {
            const filter = inputValue.toLowerCase()
            return allOptions.filter(
              (option) =>
                (option.label as string).toLowerCase().includes(filter) || countryService.getFullName(option.value).toLowerCase().includes(filter) || (name === 'recipient' && option.caption === 'create_new')
            )
          }}
          renderOption={(liProps, option) => (
            <li {...liProps}>
                {withFlag && option.flag && (
                  <Box className={classnames({ [classes.flagWrapper]: open, [classes.flag]: true })}>
                    <Flag country={option.flag as string} size={16}/>
                  </Box>
                )}
                {option.avatar && <Avatar src={option.avatar} alt="user" className={classes.avatar}/>}
                {option.image && <CardMedia component="img" src={option.image} className={classes.image}/>}
                {option.icon && <Icon icon={option.icon} size={22} className={classes.icon}/>}
                {name === 'recipient' && (
                  <IconButton
                    color={'primary'}
                    className={classes.recipientAvatar}
                    onClick={() => {
                    }}
                  >
                    {option.caption === 'create_new' ? (
                      <Icon icon="plus" size={10} color="#191B1D"/>
                    ) : (
                      <Typography variant="subtitle1" color="inherit">
                        {renderRecipientAvatar(option.label as string)}
                      </Typography>
                    )}
                  </IconButton>
                )}
                {typeof option.label === 'string' ? (
                  <Typography variant="subtitle2" color="inherit" className={classes.menuLabel}>
                    {option.label}
                  </Typography>
                ) : (
                  option.label
                )}
            </li>
          )}
        />
      ) : (
        <PoorSelect
          options={options}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={(options.length === 1 && !!props.value) || !options.length ? false : open}
          name={name}
          inputProps={inputProps}
          withFlag={withFlag}
          t={t}
          {...props}
        />
      )}
      <FormHelperText>{!!errorMessage && t(errorMessage)}</FormHelperText>
    </FormControl>
  )
}

export const FormikSelect = ({ className = '', field, form, label, ...meta }: IFormikSelectProps) => {
  const { classes } = useStyles()

  const { t, i18n } = useTranslation('validation')
  const [open, setOpen] = useState<boolean>(false)

  const { name, value, onChange } = field
  const { errors, touched, setFieldTouched, setFieldValue } = form
  const { options, withFlag, search, title, inputProps: topInputProps } = meta

  const error = useMemo(() => (touched[name] ? (errors[name] as string) || '' : ''), [touched, errors, name])

  const onFocus = useCallback(() => {
    setFieldTouched(name, false)
  }, [setFieldTouched, name])

  const onBlur = useCallback(() => {
    setFieldTouched(name, true)
  }, [setFieldTouched, name])

  const onOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen, name])

  const setAutocompleteValue = useCallback(
    (e: ChangeEvent<{}>, data: TOption | TOption[] | null) => {
      if (!!data && !Array.isArray(data) && data.value) {
        setFieldValue(name, data.value)
      } else if (Array.isArray(data)) {
        const values = data.reduce((acc: string[], item) => [...acc, item.value], [])
        setFieldValue(name, values)
      }
    },
    [setFieldValue]
  )

  return (
    <FormControl fullWidth error={!!error} className={classnames(classes.formikRoot, className)}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      {search ? (
        <Autocomplete
          autoSelect
          disableClearable
          clearOnEscape
          popupIcon={<Icon icon="chevron-right" size={18}/>}
          multiple={meta.multiple || false}
          open={open}
          onOpen={onOpen}
          onClose={() => setOpen(false)}
          onFocus={onFocus}
          onBlur={onBlur}
          classes={{
            root: classes.autoCompleteRoot,
            paper: classes.paper,
            option: meta.multiple ? classes.optionMultiple : classes.option,
          }}
          renderInput={(params) => {
            const { InputProps, inputProps, ...restParams } = params
            const selectedOption = meta.multiple
              ? options.filter((option) => value.includes(option.value)).map((option) => option.label)
              : options.find((option) => option.value === value)
            if (!open && value && !(inputProps as any).value && selectedOption) {
              (inputProps as any).value = Array.isArray(selectedOption) ? selectedOption.join(', ') : selectedOption.label
            }
            return (
              <TextField
                variant="outlined"
                {...restParams}
                inputProps={{ ...inputProps, ...topInputProps }}
                InputProps={{
                  ...InputProps,
                  className: '',
                  startAdornment:
                    !open && selectedOption && !Array.isArray(selectedOption) && selectedOption.flag ? (
                      <InputAdornment position="start">
                        <Flag country={selectedOption.flag as string} size={18}/>
                      </InputAdornment>
                    ) : null,
                }}
              />
            )
          }}
          onChange={setAutocompleteValue}
          options={options}
          filterOptions={(allOptions, { inputValue }) => {
            const filter = inputValue.toLowerCase()
            return allOptions
              .filter((option) => (option.label as string).toLowerCase().includes(filter)
                || countryService.getFullName(option.value).toLowerCase().includes(filter))
          }}
          renderOption={(liProps, option) => (
            <li {...liProps}>
              {meta.multiple && <Checkbox checked={(value as string[]).includes(option.value)} className={classes.multiCheckbox}/>}
              {withFlag &&
                option.flag &&
                (meta.multiple ? (
                  <Box width={24} style={{ borderWidth: 0 }} className={classnames(classes.flagWrapper, classes.flag)}>
                    <Flag country={option.flag as string} size={24}/>
                  </Box>
                ) : (
                  <Box className={classnames({ [classes.flagWrapper]: open, [classes.flag]: true })}>
                    <Flag country={option.flag as string} size={16}/>
                  </Box>
                ))}
              {option.avatar && <Avatar src={option.avatar} alt="user" className={classes.avatar}/>}
              {option.image && <CardMedia component="img" src={option.image} className={classes.image}/>}
              {option.icon && <Icon icon={option.icon} size={22} className={classes.icon}/>}
              {typeof option.label === 'string' ? (
                <Typography variant="subtitle2" color="inherit" className={classes.menuLabel}>
                  {option.label}
                </Typography>
              ) : (
                option.label
              )}
            </li>
          )}
        />
      ) : (
        <PoorSelect
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          open={(options.length === 1 && !!value) ? false : open}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          t={t}
          {...meta}
        />
      )}
      <FormHelperText>{error && t(error)}</FormHelperText>
      {title && <FormHelperText>{title}</FormHelperText>}
    </FormControl>
  )
}

export const LanguageSelect = (props: Omit<SelectProps, 'renderValue'>) => {
  const { classes } = useStyles()

  const { i18n } = useTranslation()

  const options = useMemo(
    () =>
      Object.keys((i18n as any).languagesMap).map((key) => {
        return {
          value: key,
          label: (i18n as any).languagesMap[key].label,
          flag: (i18n as any).languagesMap[key].country,
        }
      }),
    [i18n]
  )

  const parsedLang = useMemo(() => {
    if (i18n.language.includes('-')) {
      return i18n.language.split('-')[0].toLowerCase()
    } else if (i18n.language.includes('_')) {
      return i18n.language.split('_')[0].toLowerCase()
    }
    return i18n.language
  }, [i18n.language])

  const onChange = useCallback((e: SelectChangeEvent<unknown>) => {
    i18n.changeLanguage(e.target.value as string).catch((err) => console.log(err))
  }, [])

  return (
    <CustomSelect
      withFlag
      name={'language'}
      value={parsedLang}
      onChange={onChange}
      options={options}
      renderValue={(value) =>
        !!value ? (
          <Box className="horizontalFlex" gap={1}>
            <Box pb={0.25}>
              <Flag country={(i18n as any).languagesMap[value as string].country} size={16}/>
            </Box>
            <Typography variant="subtitle2" color="inherit" className="text-end-ellipse">
              {(i18n as any).languagesMap[value as string].label}
            </Typography>
          </Box>
        ) : (
          ''
        )
      }
      {...props}
    />
  )
}

export default CustomSelect
